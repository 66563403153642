import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    filtersAccessibleToCurrentUser: {
      nodes: [],
      totalCount: 0,
    },
    isLoading: false,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_FILTERS_ACCESSIBLE_TO_CURRENT_USER(state, val) {
      state.filtersAccessibleToCurrentUser = val
    },
    ADD_FILTER(state, val) {
      state.filtersAccessibleToCurrentUser.nodes.unshift(val)
      state.filtersAccessibleToCurrentUser.totalCount += 1
    },
    UPDATE_FILTER(state, val) {
      const index = state.filtersAccessibleToCurrentUser.nodes.findIndex(item => item.id === val.id)
      if (index !== -1) {
        Vue.set(state.filtersAccessibleToCurrentUser.nodes, index, val)
      }
    },
    REMOVE_FILTER(state, id) {
      const index = state.filtersAccessibleToCurrentUser.nodes.findIndex(item => item.id === id)
      if (index !== -1) {
        state.filtersAccessibleToCurrentUser.nodes.splice(index, 1)
      }
    },
  },
  actions: {
    async getFiltersAccessibleToCurrentUser({ commit }, {
      q, offset, limit, sortBy, isDesc, associatedEntities,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query filtersAccessibleToCurrentUser($q: String, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean, $associatedEntities: [AssociatedEntity!]) {
            filtersAccessibleToCurrentUser(q: $q, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc, associatedEntities: $associatedEntities) {
              nodes {
                id
                name
                associatedEntity
                private
                owner {
                  id
                  name
                }
                criteria
              }
              totalCount
            }
          }`,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
            associatedEntities,
          },
        })
        commit('SET_FILTERS_ACCESSIBLE_TO_CURRENT_USER', response.data.filtersAccessibleToCurrentUser)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchFiltersIfNotLoaded({ dispatch, state }) {
      if (!state.filtersAccessibleToCurrentUser.totalCount) {
        dispatch('getFiltersAccessibleToCurrentUser', {
          offset: 0,
          limit: 1000,
        })
      }
    },
    async addFilter({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation createFilter($input: CreateFilterInput!) {
            createFilter(createFilterInput: $input) {
              id
              name
              associatedEntity
              private
              criteria
              owner {
                id
                name
              }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('ADD_FILTER', response.data.createFilter)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateFilter({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation updateFilter($input: UpdateFilterInput!) {
            updateFilter(updateFilterInput: $input) {
              id
              name
              associatedEntity
              private
              owner {
                id
                name
              }
              criteria
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_FILTER', response.data.updateFilter)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeFilter({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeFilter($id: Int!) {
            removeFilter(id: $id)
          }`,
          variables: {
            id,
          },
        })
        if (response.data.removeFilter) {
          commit('REMOVE_FILTER', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
  },
}
