import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'
import { MultiRemove, Remove, Update } from '@/@core/mixins/store/updateNodes'

export default {
  namespaced: true,
  state: {
    users: {
      nodes: [],
      totalCount: 0,
    },
    allUsers: {
      nodes: [],
      totalCount: null,
    },
    user: [],
    importUsersResult: {},
    isLoading: false,
    currentUser: null,
    fieldValues: [],
    relationValues: [],
    userInfo: [],
  },
  getters: {
    isLoggedIn: state => state.currentUser,
    fieldValues: state => state.fieldValues,
    relationValues: state => state.relationValues,
    currentUser: state => state.currentUser,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_USERS(state, val) {
      state.users = val
    },
    SET_ALL_USERS(state, val) {
      state.allUsers = val
    },
    SET_USER(state, val) {
      state.user = val
    },
    SET_CURRENT_USER(state, val) {
      state.currentUser = val
    },
    ADD_USER(state, val) {
      state.users.nodes.unshift(val)
      state.users.totalCount += 1
      state.allUsers.nodes.unshift(val)
      state.allUsers.totalCount += 1
      state.user = val
    },
    UPDATE_USER(state, val) {
      const users = state.users.nodes.findIndex(item => item.id === val.id)
      const allUsers = state.users.nodes.findIndex(item => item.id === val.id)
      const usersIndexes = [{ users }, { allUsers }]
      Update(usersIndexes, state, val, Vue)
      if (val.id === state.currentUser.id) {
        state.currentUser = val
      }
      if (val.id === state.user.id) {
        state.user = val
      }
    },
    REMOVE_USER(state, id) {
      const users = state.users.nodes.findIndex(item => item.id === id)
      const allUsers = state.users.nodes.findIndex(item => item.id === id)
      const usersIndexes = [{ users }, { allUsers }]
      Remove(usersIndexes, state)
    },
    REMOVE_USERS(state, ids) {
      const usersIndexes = [{ users: 0 }, { allUsers: 0 }]
      MultiRemove(usersIndexes, ids, state)
    },
    SET_IMPORT_USERS_RESULT(state, result) {
      state.importUsersResult = result
    },
    SET_FIELD_VALUES(state, result) {
      state.fieldValues = result
    },
    SET_RELATION_VALUES(state, result) {
      state.relationValues = result
    },
    SET_USER_INFO(state, result) {
      state.userInfo = result
    },
    RESET_USERS(state) {
      state.users = {
        nodes: [],
        totalCount: 0,
      }
      state.allUsers = {
        nodes: [],
        totalCount: null,
      }
    },
  },
  actions: {
    async getUsers({ commit }, {
      q, userType, offset, limit, sortBy, isDesc,
    }) {
      if (limit < 1000) {
        commit('SET_LOADING', true)
      }
      try {
        const response = await apolloClient.query({
          query: gql`query users($q: [String!], $userType: UserType, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            users(q: $q, userType: $userType, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                peopleHandle
                name
                description
                userType
                authMethod
                roleIds
                roles{
                  roleHandle
                }
                receiveWeeklyReminders
              }
              totalCount
            }
          }`,
          variables: {
            q,
            userType,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        if (limit === 1000) {
          commit('SET_ALL_USERS', response.data.users)
        } else {
          commit('SET_USERS', response.data.users)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getUser({ commit }, data) {
      const { id } = data
      const response = await apolloClient.query({
        query: gql`query user($id: Int!) {
            user(id: $id) {
              id
                peopleHandle
                name
                description
                userType
                authMethod
                roleIds
                receiveWeeklyReminders
              roles {
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                process{
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                roleHandle
                name
                filledBy{
                  id
                  name
                }
                id
                reportsToId
              }
            }
          }`,
        variables: {
          id,
        },
      })
      commit('SET_USER', response.data.user)
    },
    async fetchUsersIfNotLoaded({ dispatch, state }) {
      if (!state.allUsers.totalCount) {
        const val = await dispatch('getUsers', {
          offset: 0,
          limit: 1000,
          sortBy: 'name',
          isDesc: false,
        })
        return val
      }
      return null
    },
    async addUser({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation createUser($input: CreateUserInput!) {
            createUser(createUserInput: $input) {
              id
              peopleHandle
              name
              description
              userType
              authMethod
              receiveWeeklyReminders
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('ADD_USER', response.data.createUser)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateUser({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation updateUser($input: UpdateUserInput!) {
            updateUser(updateUserInput: $input) {
              id
              peopleHandle
              name
              description
              userType
              authMethod
              roleIds
              receiveWeeklyReminders
              roles {
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                id
                process{
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                roleHandle
                name
                filledBy{
                  id
                  name
                }
                reportsToId
              }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_USER', response.data.updateUser)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async UserGetInfo({ commit }, data) {
      const response = await apolloClient.query({
        query: gql`query userGetInfo($id: Int!) {
          userGetInfo(id: $id){
            roles
            tasks
          }
          }`,
        variables: {
          id: data,
        },
      })
      commit('SET_USER_INFO', response)
    },
    async removeUser({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeUser($id: Int!) {
            removeUser(id: $id)
          }`,
          variables: {
            id,
          },
        })
        if (response.data.removeUser) {
          commit('REMOVE_USER', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeUsers({ commit }, ids) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeUsers($ids: [Int!]!) {
            removeUsers(ids: $ids)
          }`,
          variables: {
            ids,
          },
        })
        if (response.data.removeUsers) {
          commit('REMOVE_USERS', ids)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async importUsers({ commit }, csvText) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation importUsers($csvText
          : String!) {
            importUsers(csvText: $csvText) {
              created
              updated
              errors {
                line
                message
                record
              }
              messages{
                line
                message
                record
              }
            }
          }`,
          variables: {
            csvText,
          },
        })
        if (response.data.importUsers) {
          commit('SET_IMPORT_USERS_RESULT', response.data.importUsers)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getCurrentUser({ commit }) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`query currentUser {
            currentUser {
              id
              peopleHandle
              name
              description
              userType
              authMethod
              roleIds
              receiveWeeklyReminders
              roles {
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                id
                process{
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                roleHandle
                name
                filledBy{
                  id
                  name
                }
                reportsToId
              }
            }
          }`,
        })
        if (response.data.currentUser) {
          commit('SET_CURRENT_USER', response.data.currentUser)
        }
      } catch (e) {
        throw Error('There is no user with these credentials.')
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async fetchCurrentUserIfNotLoaded({ dispatch, state }) {
      if (!state.currentUser) {
        await dispatch('getCurrentUser').then(() => state.currentUser)
      }
    },
    async getPossibleValuesForFieldFromUserEntity({ commit }, field) {
      const response = await apolloClient.query({
        query: gql`query getPossibleValuesForFieldFromUserEntity($field: String!) {
            getPossibleValuesForFieldFromUserEntity(field: $field) 
          }`,
        variables: {
          field,
        },
      })
      if (response.data.getPossibleValuesForFieldFromUserEntity) {
        commit('SET_FIELD_VALUES', response.data.getPossibleValuesForFieldFromUserEntity)
      }
    },
    async getPossibleValuesForRelationFromUserEntity({ commit }, relation) {
      const response = await apolloClient.query({
        query: gql`query getPossibleValuesForRelationFromUserEntity($relation: String!) {
            getPossibleValuesForRelationFromUserEntity(relation: $relation) {
              id
              name
            }
          }`,
        variables: {
          relation,
        },
      })
      if (response.data.getPossibleValuesForRelationFromUserEntity) {
        commit('SET_RELATION_VALUES', response.data.getPossibleValuesForRelationFromUserEntity)
      }
    },
    async cancelUsersImport({ commit }) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        await apolloClient.mutate({
          mutation: gql`mutation cancelUsersImport {
            cancelUsersImport
          }`,
        })
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getUsersStatsItems({ commit }, {
      filter, userType, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query usersStatsItems($filter: String!, $userType: UserType, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            usersStatsItems(filter: $filter, userType: $userType, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                peopleHandle
                name
                description
                userType
                authMethod
                roleIds
                roles{
                  roleHandle
                }
                receiveWeeklyReminders
              }
              totalCount
            }
          }`,
          variables: {
            filter,
            userType,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })

        commit('SET_USERS', response.data.usersStatsItems)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getAllUsers() {
      try {
        const response = await apolloClient.query({
          query: gql`query {
            allUsers {
              id
              peopleHandle
              name
              description
              userType
              authMethod
              roleIds
              roles{
                roleHandle
              }
              receiveWeeklyReminders
            }
          }`,
        })

        return response.data.allUsers
      } finally {
        // do nothing
      }
    },
    resetUsers({ commit }) {
      commit('RESET_USERS')
    },
  },
}
