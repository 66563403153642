/* eslint-disable import/order */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import Matrix from '@/views/matrix/Matrix.vue'
import matrixList from '@/views/matrix/MatrixList.vue'
import PersonalDashboard from '@/views/dashboard/PersonalDashboard.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  fallback: true,
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard-personal' },
      meta: {
        resource: 'dashboard',
        action: 'read',
      },
    },
    {
      path: '/dashboard/personal',
      name: 'dashboard-personal',
      component: PersonalDashboard,
      meta: {
        resource: 'myDashboard',
        action: 'read',
      },
    },
    {
      path: '/login/:tenant?',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/roles',
      name: 'role-list',
      component: () => import('@/views/role/RoleList.vue'),
      meta: {
        resource: 'role',
        action: 'read',
      },
    },
    // *===============================================---*
    // *-------------------- TENANT ----------------------*
    // *===============================================---*
    {
      path: '/tenant-OtrEF',
      name: 'organization-add',
      component: () => import('@/views/organization/OrganizationAdd.vue'),
      meta: {
        resource: 'role',
        action: 'read',
      },
    },
    // *===============================================---*
    // *-------------------- PEOPLE ----------------------*
    // *===============================================---*
    {
      path: '/people',
      name: 'user-list',
      component: () => import('@/views/user/UserList.vue'),
      meta: {
        resource: 'user',
        action: 'read',
      },
    },
    {
      path: '/people/:id',
      name: 'user-display',
      component: () => import('@/views/user/UserDisplay.vue'),
      meta: {
        resource: 'user',
        action: 'read',
      },
    },

    // *===============================================---*
    // *----------------- PROCESSES ----------------------*
    // *===============================================---*
    {
      path: '/processes',
      name: 'process-list',
      component: () => import('@/views/process/ProcessList.vue'),
      meta: {
        resource: 'process',
        action: 'read',
      },
    },
    {
      path: '/processes/:id',
      name: 'process-display',
      component: () => import('@/views/process/ProcessDisplay.vue'),
      meta: {
        resource: 'process',
        action: 'read',
      },
    },
    // {
    //   path: '/tasks',
    //   name: 'task-list',
    //   component: () => import('@/views/task/TaskListOld.vue'),
    //   meta: {
    //     resource: 'task',
    //     action: 'read',
    //   },
    // },
    {
      path: '/tasks',
      name: 'task-list',
      component: () => import('@/views/task/TaskList.vue'),
      meta: {
        resource: 'task',
        action: 'read',
      },
    },

    // *===============================================---*
    // *------------- PERSONAL BUCKET --------------------*
    // *===============================================---*
    {
      path: '/myRoles',
      name: 'my-role-list',
      component: () => import('@/views/role/RoleList.vue'),
      meta: {
        resource: 'myRole',
        action: 'read',
      },
    },
    {
      path: '/myProfile/:id',
      name: 'my-profile',
      component: () => import('@/views/user/profile/UsersView.vue'),
      meta: {
        resource: 'myProfile',
        action: 'read',
      },
    },
    {
      path: '/userProfile/:id',
      name: 'user-profile',
      component: () => import('@/views/user/profile/UsersView.vue'),
      meta: {
        resource: 'userProfile',
        action: 'read',
      },
    },
    {
      path: '/myProcesses',
      name: 'my-process-list',
      component: () => import('@/views/process/ProcessList.vue'),
      meta: {
        resource: 'myProcess',
        action: 'read',
      },
    },
    {
      path: '/myTasks',
      name: 'my-task-list',
      component: () => import('@/views/task/TaskList.vue'),
      meta: {
        resource: 'myTask',
        action: 'read',
      },
    },

    // *===============================================---*
    // *--------------------- TOOLS ----------------------*
    // *===============================================---*
    {
      path: '/matrix',
      name: 'matrix',
      component: Matrix,
      meta: {
        resource: 'tool',
        action: 'read',
      },
      props: true,
    },
    {
      path: '/matrix-list',
      name: 'matrix-list',
      component: matrixList,
      meta: {
        resource: 'tool',
        action: 'read',
      },
    },
    {
      path: '/labels',
      name: 'label-list',
      component: () => import('@/views/label/LabelList.vue'),
      meta: {
        resource: 'tool',
        action: 'read',
      },
    },
    {
      path: '/processCategories',
      name: 'process-category-list',
      component: () => import('@/views/processCategory/ProcessCategoryList.vue'),
      meta: {
        resource: 'tool',
        action: 'read',
      },
    },
    {
      path: '/settings',
      name: 'setting-list',
      component: () => import('@/views/setting/SettingList.vue'),
      meta: {
        resource: 'tool',
        action: 'read',
      },
    },
    {
      path: '/filters',
      name: 'filter-list',
      component: () => import('@/views/filter/FilterList.vue'),
      meta: {
        resource: 'tool',
        action: 'read',
      },
    },

    // demo routes
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  let isLoggedIn
  await store.dispatch('user/fetchCurrentUserIfNotLoaded').then(async () => {
    isLoggedIn = true
    if (store.state.user.currentUser.userType === 'User') await store.dispatch('auth/setRole', 'user')
    else if (store.state.user.currentUser.userType === 'Admin') await store.dispatch('auth/setRole', 'admin')
    else if (store.state.user.currentUser.userType === 'Manager') await store.dispatch('auth/setRole', 'manager')
  }).catch(() => {
    isLoggedIn = false
  })
  if (to.name !== 'auth-login') {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) {
        return next({ name: 'auth-login' })
      }
      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next('/')
    }
    return next()
  }

  if (to.name === 'auth-login' && isLoggedIn) {
    next('/')
  } else if (to.name === 'auth-login' && to.params.tenant) {
    next(false)
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
