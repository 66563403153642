import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase'
import { setContext } from 'apollo-link-context'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API_URL,
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const error = graphQLErrors[0]
    if (error.message.includes('Cant delete role while it is related to Processes')) {
      error.message = 'Cant delete role while it is related to Processes'
    }
    Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Error',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: error.message,
      },
    })
  }
})

const authLink = setContext(() => firebase
  .auth()
  .currentUser.getIdToken()
  .then(token => ({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    http: {
      includeQuery: true,
    },
  })))

const links = ApolloLink.from([authLink, errorLink, httpLink])

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
})

// Install the vue plugin
Vue.use(VueApollo)

export default new VueApollo({
  defaultClient: apolloClient,
})
