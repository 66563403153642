<template>
  <b-modal
    id="modal-import-result"
    centered
    title="Import Result"
    size="lg"
    :hide-footer="true"
  >
    <b-list-group>
      <!-- Created -->
      <b-list-group-item
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100">
          <div class="mr-1">
            Created:
          </div>
          <div class="text-secondary">
            {{ importResult.created }}
          </div>
        </div>
      </b-list-group-item>

      <!-- Updated -->
      <b-list-group-item
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100">
          <div class="mr-1">
            Updated:
          </div>
          <div class="text-secondary">
            {{ importResult.updated }}
          </div>
        </div>
      </b-list-group-item>

      <!-- Errors -->
      <b-list-group-item
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100">
          <div class="mr-1">
            Errors:
          </div>
          <div class="text-secondary">
            {{ importResult.errors ? importResult.errors.length : 0 }}
          </div>
        </div>
        <div>
          <b-table
            v-show="importResult.errors && importResult.errors.length !== 0"
            class="mt-1"
            :outlined="true"
            striped
            hover
            :items="importResult.errors"
            :fields="tableColumns"
          />
        </div>
      </b-list-group-item>

      <!-- Messages -->
      <b-list-group-item
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100">
          <div class="mr-1">
            Messages:
          </div>
          <div class="text-secondary">
            {{ importResult.messages ? importResult.messages.length : 0 }}
          </div>
        </div>
        <div>
          <b-table
            v-show="importResult.messages && importResult.messages.length !== 0"
            class="mt-1"
            :outlined="true"
            striped
            hover
            :items="importResult.messages"
            :fields="tableColumns"
          />
        </div>
      </b-list-group-item>
    </b-list-group>

    <!-- Action Buttons -->
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        @click="hideImportResultModal()"
      >
        Cancel
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="outline-secondary"
        @click="hideImportResultModal()"
      >
        OK
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BListGroup,
  BListGroupItem,
  BTable,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
    BListGroup,
    BListGroupItem,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      importResult: {},
      tableColumns: [
        {
          key: 'line', label: 'line', class: 'line-edit',
        },
        {
          key: 'message', label: 'message', class: 'message-edit',
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['importUsersResult']),
    ...mapState('role', ['importRolesResult']),
    ...mapState('process', ['importProcessesResult']),

  },
  watch: {
    importUsersResult(newValue) {
      if (newValue) {
        this.$bvModal.show('modal-import-result')
        this.importResult = newValue
        this.getUsers({
          offset: 0,
          limit: 1000,
        })
      }
    },
    importRolesResult(newValue) {
      if (newValue) {
        this.$bvModal.show('modal-import-result')
        this.importResult = newValue
        this.getRoles({
          offset: 0,
          limit: 1000,
        })
      }
    },
    importProcessesResult(newValue) {
      if (newValue) {
        this.$bvModal.show('modal-import-result')
        this.importResult = newValue
        this.getProcesses({
          offset: 0,
          limit: 1000,
        })
      }
    },
  },
  methods: {
    ...mapActions('user', ['getUsers']),
    ...mapActions('role', ['getRoles']),
    ...mapActions('process', ['getProcesses']),
    hideImportResultModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-import-result')
      })
    },
  },
}
</script>

<style lang="scss">
  .line-edit{
    font-weight: bold;
    padding:0.72rem 2rem!important;
  }
  .message-edit{
    font-weight: bold;
    padding:0.72rem 2rem!important;
  }
</style>
