<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">

      <b-col
        lg="4"
        cols="12"
      >
        <process-card :data="data.processes" />

      </b-col>
      <b-col
        lg="4"
        cols="12"
      >
        <tasks :data="data.lateTasks" />
      </b-col>
      <b-col
        lg="4"
        cols="12"
      >
        <roles :data="data.roles" />
      </b-col>

    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import Roles from '@/views/dashboard/statsCard/OrgRolesCard.vue'
import Tasks from '@/views/dashboard/statsCard/Tasks.vue'
import ProcessCard from './statsCard/ProcessCard.vue'

export default {
  components: {
    BRow,
    BCol,
    Roles,
    Tasks,
    ProcessCard,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('stats', ['personalStatistics', 'pIsLoading']),
  },
  created() {
    this.getUserStatistics().then(() => {
      this.data = this.personalStatistics
    })
  },
  methods: {
    ...mapActions('stats', ['fetchPersonalStatisticsIfNotLoaded', 'getUserStatistics']),
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/pages/dashboard-ecommerce';
@import '../../@core/scss/vue/libs/chart-apex';
</style>
