<template>
  <div>
    <ImportAddMatrixModal
      entity="Matrix"
      title="Add Matrix"
    />
    <MatrixAdd
      :is-sidebar-active.sync="isAddNewMatrixSidebarActive"
      :is-edit="isAddNewMatrixSidebarInDisplayMode"
      :value.sync="activeRecord"
      @saving="getMatrixTable"
    />
    <b-card
      no-body
      class="mb-0 container-card"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row cols="12">
          <!-- Search -->
          <b-col sm="10">
            <div>
              <div
                style="position: absolute; left: 28px; top: 7px; z-index: 5000"
                @click="getMatrixTable"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Perform Search'"
                  size="18"
                  icon="SearchIcon"
                  class="mr-1"
                />
              </div>
              <b-form-input
                v-model="searchQuery"
                style="padding-left: 40px !important;"
                placeholder="Search..."
                type="text"
                @keyup.enter="getMatrixTable"
              />
              <div
                v-if="searchQuery.length !== 0"
                style="position: absolute; top: 7px; right: 28px"
                @click="clearSearch"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Clear Search'"
                  icon="XIcon"
                  size="18"
                />
              </div>
            </div>
          </b-col>
          <b-col md="2">
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                variant="primary"
                @click="editRecord(null)"
              >
                <span class="text-nowrap">Add Matrix</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProcessListTable"
        class="position-relative"
        :items="matrixOptions.nodes"
        :busy="isLoading"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        show-empty
        empty-text="No matching records found"
        hover
        @row-clicked="displayRecord"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              type="grow"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(column)="data">
          <span>
            <b-badge
              pill
              variant="light-success"
              class="mr-1"
            >
              {{ data.item.col }}
            </b-badge>
          </span>
        </template>

        <template #cell(row)="data">
          <span>
            <b-badge
              pill
              variant="light-info"
              class="mr-1"
            >
              {{ data.item.row }}
            </b-badge>
          </span>
        </template>

        <template #cell(owner)="data">
          <span>
            {{ data.item.owner.name }}
          </span>
        </template>

        <template #cell(private?)="data">
          <span v-if="data.item.privacy">
            Yes
          </span>
          <span v-else>
            NO
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="action-buttons">
            <b-button
              :id="`row-${data.item.id}-edit-icon`"
              :style="[data.item.owner.name == user.name ? 'visibility: visible' : 'visibility: hidden']"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Edit"
              class="cursor-pointer"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <b-button
              v-if="data.item.owner.name == user.name"
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of
              {{ paginationMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="matrixOptions.totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BTooltip,
  BSpinner,
  BBadge,
  BFormInput,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState, mapActions } from 'vuex'
import { selectPosition } from '@core/mixins/ui/select'
import ImportAddMatrixModal from '@/views/shared/ImportAddMatrixModal.vue'
import MatrixAdd from '@/views/matrix/MatrixAdd.vue'

export default {
  name: 'MatrixList',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BTooltip,
    BSpinner,
    BBadge,
    BFormInput,
    vSelect,
    BPagination,
    ImportAddMatrixModal,
    MatrixAdd,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [selectPosition],
  data() {
    return {
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'column', label: 'Column' },
        { key: 'row', label: 'Row' },
        { key: 'owner' },
        { key: 'private?' },
        { key: 'actions' },
      ],
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      search: '',
      isAddNewMatrixSidebarActive: false,
      isAddNewMatrixSidebarInDisplayMode: false,
      activeRecord: null,
    }
  },
  computed: {
    ...mapState({
      savedMatrix: state => state.matrix.savedMatrix,
      matrixOptions: state => state.matrix.matrixTable,
      deleted: state => state.matrix.deletedMatrix,
      user: state => state.user.currentUser,
      isLoading: state => state.matrix.isLoading,
    }),
    paginationMeta() {
      if (this.matrixOptions.nodes === undefined) {
        return true
      }
      return {
        from:
          this.perPage * (this.currentPage - 1)
          + (this.matrixOptions.nodes.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.matrixOptions.nodes.length,
        of: this.matrixOptions.totalCount,
      }
    },
    queryParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
      }
    },
  },
  watch: {
    savedMatrix() {
      const title = `Matrix ${this.savedMatrix} Created`
      this.showToast(title)
      this.getMatrixTable()
    },
    deleted() {
      const title = 'Matrix Deleted'
      this.showToast(title)
      this.getMatrixTable()
    },
    queryParams() {
      this.getMatrixTable()
    },
  },
  mounted() {
    this.getMatrixTable()
  },
  methods: {
    ...mapActions('matrix', ['getSavedMatrixes']),
    editRecord(activeRecord) {
      this.activeRecord = activeRecord
      this.isAddNewMatrixSidebarActive = true
      this.isAddNewMatrixSidebarInDisplayMode = !!activeRecord
    },
    getMatrixTable() {
      this.getSavedMatrixes({
        q: this.searchQuery,
        page: this.currentPage,
        perPage: this.perPage,
      })
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    deleteRecord(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure that you want to delete this Matrix?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('matrix/deleteMatrix', id)
          }
        })
    },

    // search related methods
    clearSearch() {
      this.searchQuery = ''
      this.getMatrixTable()
    },
    displayRecord(item) {
      this.$router.push({ path: '/matrix', query: { row: item.row, column: item.col, matrixId: item.id }, params: { owner: (item.owner.name === this.user.name) } })
    },
  },
}

</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.container-card{
    min-width: max-content;
}
</style>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-select";
</style>
