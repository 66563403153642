<template>
  <div>
    <b-modal
      id="modal-add-matrix"
      centered
      :title="title"
      size="lg"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(SaveMatrix)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <h6>Name</h6>
            <b-form-group>
              <b-form-input
                id="matrix-1"
                v-model="name"
                :state="getValidationState(validationContext)"
                placeholder="Please provide matrix name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Column Select -->
          <validation-provider
            #default="validationContext"
            name="Column"
            rules="required"
          >

            <h6>Column</h6>
            <b-form-group :state="getValidationState(validationContext)">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="labelsPrefix"
                :value="selectedColumn"
                :reduce="label => label.label"
                placeholder="please choose matrix column"
                @input="setSelectedColumn"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Row"
            rules="required"
          >

            <h6>Row</h6>
            <b-form-group :state="getValidationState(validationContext)">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="labelsPrefix"
                :value="selectedRow"
                :reduce="label => label.label"
                placeholder="please choose matrix row"
                @input="setSelectedRow"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
          <b-form-checkbox
            v-model="selected"
            value="private"
            class="custom-control-success"
          >
            Private
          </b-form-checkbox>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="handleSubmit()"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <ImportResultModal />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ImportResultModal from '@/views/shared/ImportResultModal.vue'

export default {
  components: {
    BModal,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    ImportResultModal,
    BFormCheckbox,
    vSelect,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    entity: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      form: this.getDefaultForm(),
      name: null,
      selectedColumn: null,
      selectedRow: null,
      selected: 'private',
      columnOptions: [],
      rowOptions: [],
    }
  },
  computed: {
    ...mapState({
      labelsPrefix: state => state.matrix.labelsPrefixes,
    }),
  },
  watch: {
    labelsPrefix() {
      this.columnOptions = this.labelsPrefix
      this.rowOptions = this.labelsPrefix
    },
  },
  mounted() {
    this.getLabelsPrefix()
  },
  methods: {
    ...mapActions('matrix', ['getLabelsPrefix', 'createSavedMatrix']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    hideImportAddMatrixModal() {
      this.resetForm()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-matrix')
      })
    },
    async onFileChanged(e) {
      this.form.csvText = await this.getFileContent(e)
    },
    getFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function onLoad(event) {
          resolve(event.target.result)
        }
        reader.onerror = function onError(error) {
          reject(error)
        }
        reader.readAsText(file, 'UTF-8')
      })
    },
    setSelectedColumn(value) {
      this.selectedColumn = value
    },
    setSelectedRow(value) {
      this.selectedRow = value
    },

    onSubmit() {
      let action
      if (this.entity === 'user') action = this.$store.dispatch('user/importUsers', this.form.csvText)
      else if (this.entity === 'role') action = this.$store.dispatch('role/importRoles', this.form.csvText)
      else if (this.entity === 'process') action = this.$store.dispatch('process/importProcesses', this.form.csvText)
      if (action) {
        action.then(() => {
          this.$bvModal.hide('modal-add-matrix')
          this.resetForm()
        })
      }
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      this.form = this.getDefaultForm()
    },
    getDefaultForm() {
      return {
        csvText: null,
        csvFile: null,
      }
    },
    SaveMatrix() {
      const col = this.selectedColumn
      const row = this.selectedRow
      const privacy = this.selected === 'private'
      const { name } = this

      this.createSavedMatrix({
        col, row, privacy, name,
      })

      this.hideImportAddMatrixModal()
    },
  },
}
</script>
