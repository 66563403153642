import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    labels: {
      nodes: [],
      totalCount: 0,
    },
    allLabels: {
      nodes: [],
      totalCount: 0,
    },
    isLoading: false,
  },
  getters: {
    // isLoggedIn: state => state.currentLabel,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_LABELS(state, val) {
      state.labels = val
    },
    SET_ALL_LABELS(state, val) {
      state.allLabels = val
    },
    ADD_LABEL(state, val) {
      state.labels.nodes.unshift(val)
      state.labels.totalCount += 1
    },
    UPDATE_LABEL(state, val) {
      const index = state.labels.nodes.findIndex(item => item.id === val.id)
      if (index !== -1) {
        Vue.set(state.labels.nodes, index, val)
      }
    },
    REMOVE_LABEL(state, id) {
      const index = state.labels.nodes.findIndex(item => item.id === id)
      if (index !== -1) {
        state.labels.nodes.splice(index, 1)
      }
    },
  },
  actions: {
    async getLabels({ commit }, {
      q, labelType, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)

      try {
        const response = await apolloClient.query({
          query: gql`query labels($q: String, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            labels(q: $q, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                label
                processes{
                  id
                }
              }
              totalCount
            }
          }`,
          variables: {
            q,
            labelType,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })

        if (limit === 1000) {
          commit('SET_ALL_LABELS', response.data.labels)
        } else {
          commit('SET_LABELS', response.data.labels)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchLabelsIfNotLoaded({ dispatch, state }) {
      if (!state.labels.totalCount) {
        dispatch('getLabels', {
          offset: 0,
          limit: 1000,
          sortBy: 'label',
          isDesc: false,
        })
      }
    },
    async addLabel({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation createLabel($input: CreateLabelInput!) {
            createLabel(createLabelInput: $input) {
              id
              label
              processes {
                id
              }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('ADD_LABEL', response.data.createLabel)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateLabel({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation updateLabel($input: UpdateLabelInput!) {
            updateLabel(updateLabelInput: $input) {
              id
              label
              processes {
                id
              }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_LABEL', response.data.updateLabel)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeLabel({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeLabel($id: Int!) {
            removeLabel(id: $id)
          }`,
          variables: {
            id,
          },
        })
        if (response.data.removeLabel) {
          commit('REMOVE_LABEL', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
  },
}
