/* eslint-disable indent */
export function Update(IndexesArray, state, val, Vue) {
    IndexesArray.forEach(element => {
        const key = Object.keys(element)
        if (element[key] !== -1) {
            Vue.set(state[key].nodes, element[key], val)
        }
    })
}
export function Remove(IndexesArray, state) {
    IndexesArray.forEach(element => {
        const key = Object.keys(element)
        if (element[key] !== -1) {
            state[key].nodes.splice(element[key], 1)
        }
    })
}
export function MultiRemove(IndexesArray, ids, state) {
    IndexesArray.forEach(element => {
        const key = Object.keys(element)
        for (let i = 0; i < ids.length; i += 1) {
            const index = state[key].nodes.findIndex(item => item.id === ids[i])
            if (index !== -1) {
                state[key].nodes.splice(index, 1)
            }
          }
    })
}

// export { Update, UpdateSplice }
