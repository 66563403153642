import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'
import { MultiRemove, Remove, Update } from '@/@core/mixins/store/updateNodes'

export const frequencyFragment = gql`
  fragment FrequencyFragment on Frequency {
    Jan {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Jan {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Feb {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Mar {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Apr {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    May {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Jun {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Jul {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Aug {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Sep {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Oct {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Nov {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Dec {
      days
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
    Interval { 
      RepeatEvery
      StartOn
      RepeatOn
    }
  }
  `

export default {
  namespaced: true,
  state: {
    processes: {
      nodes: [],
      totalCount: 0,
    },
    allProcesses: {
      nodes: [],
      totalCount: 0,
    },
    allProcessesForTasks: {
      nodes: [],
      totalCount: 0,
    },
    importProcessesResult: {},
    isLoading: false,
    currentUserProcesses: {
      nodes: [],
      totalCount: 0,
    },
    fieldValues: [],
    relationValues: [],
    process: [],
  },
  getters: {
    fieldValues: state => state.fieldValues,
    relationValues: state => state.relationValues,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_PROCESSES(state, val) {
      state.processes = val
    },
    SET_ALL_PROCESSES(state, val) {
      state.allProcesses = val
    },
    SET_ALL_PROCESSES_FOR_TASKS(state, val) {
      state.allProcessesForTasks = val
    },
    SET_CURRENT_USER_PROCESSES(state, val) {
      state.currentUserProcesses = val
    },
    ADD_PROCESS(state, val) {
      state.processes.nodes.unshift(val)
      state.processes.totalCount += 1
      state.allProcesses.nodes.unshift(val)
      state.allProcesses.totalCount += 1
    },
    UPDATE_PROCESS(state, val) {
      const processes = state.processes.nodes.findIndex(item => item.id === val.id)
      const allProcesses = state.allProcesses.nodes.findIndex(item => item.id === val.id)
      const currentUserProcesses = state.currentUserProcesses.nodes.findIndex(item => item.id === val.id)
      const processesIndexes = [{ processes }, { allProcesses }, { currentUserProcesses }]
      Update(processesIndexes, state, val, Vue)
      state.process = val
    },
    REMOVE_PROCESS(state, id) {
      const processes = state.processes.nodes.findIndex(item => item.id === id)
      const allProcesses = state.allProcesses.nodes.findIndex(item => item.id === id)
      const currentUserProcesses = state.currentUserProcesses.nodes.findIndex(item => item.id === id)
      const processesIndexes = [{ processes }, { allProcesses }, { currentUserProcesses }]
      Remove(processesIndexes, state)
    },
    REMOVE_PROCESSES(state, ids) {
      const processesIndexes = [{ processes: 0 }, { allProcesses: 0 }, { currentUserProcesses: 0 }]
      MultiRemove(processesIndexes, ids, state)
    },
    SET_IMPORT_PROCESSES_RESULT(state, result) {
      state.importProcessesResult = result
    },
    SET_FIELD_VALUES(state, result) {
      state.fieldValues = result
    },
    SET_RELATION_VALUES(state, result) {
      state.relationValues = result
    },
    RESET_PROCESSES(state) {
      state.processes = {
        nodes: [],
        totalCount: 0,
      }

      state.allProcesses = {
        nodes: [],
        totalCount: 0,
      }

      state.currentUserProcesses = {
        nodes: [],
        totalCount: 0,
      }
    },
  },
  actions: {
    async getProcesses({ commit }, {
      q, offset, limit, sortBy, isDesc,
    }) {
      // commit('RESET_PROCESS')
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query processes($q: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
              processes(q: $q, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
                nodes {
                  id
                  processHandle
                  name
                  description
                  frequencyType
                  frequency {
                    ...FrequencyFragment
                  }
                  labels {
                    id
                    label
                  }
                  assignedRoles {
                    id
                    roleHandle
                    name
                  }

                  docs{
                    id
                  name
                  value
                }

                  role {
                    id
                    roleHandle
                    name
                  }
                  cat{
                    id
                    prefix
                    description
                  }

                }
                totalCount
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        if (limit === 1000) {
          commit('SET_ALL_PROCESSES', response.data.processes)
        } else {
          commit('SET_PROCESSES', response.data.processes)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getProcessesForTasks({ commit }, {
      q, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query processesForTasks($q: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
              processesForTasks(q: $q, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
                nodes {
                  id
                  processHandle
                  name
                  description
                  frequencyType
                  frequency {
                    ...FrequencyFragment
                  }
                  assignedRoles {
                    id
                    roleHandle
                    name
                  }
                }
                totalCount
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        commit('SET_ALL_PROCESSES_FOR_TASKS', response.data.processesForTasks)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchProcessesIfNotLoaded({ dispatch, state }) {
      if (state.allProcesses.totalCount === 0) {
        dispatch('getProcesses', {
          offset: 0,
          limit: 1000,
          sortBy: 'name',
          isDesc: false,
        })
      }
    },
    async fetchProcessesForTasksIfNotLoaded({ dispatch }) {
      dispatch('getProcessesForTasks', {
        offset: 0,
        limit: 1000,
      })
    },
    async refetchProcessesIfNotLoaded({ dispatch }) {
      dispatch('getProcesses', {
        offset: 0,
        limit: 1000,
        sortBy: 'processHandle',
        isDesc: false,
      })
    },
    async getPossibleValuesForRelationFromProcessEntity({ commit }, relation) {
      const response = await apolloClient.query({
        query: gql`query getPossibleValuesForRelationFromProcessEntity($relation: String!) {
            getPossibleValuesForRelationFromProcessEntity(relation: $relation) {
              id
              name
            }
          }`,
        variables: {
          relation,
        },
      })
      if (response.data.getPossibleValuesForRelationFromProcessEntity) {
        commit('SET_RELATION_VALUES', response.data.getPossibleValuesForRelationFromProcessEntity)
      }
    },
    async getCurrentUserProcesses({ commit }, {
      q, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query currentUserProcesses($q: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
              currentUserProcesses(q: $q, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
                nodes {
                  id
                  processHandle
                  name
                  description
                  frequencyType
                  frequency {
                    ...FrequencyFragment
                  }
                  labels {
                    id
                    label
                  }
                  assignedRoles {
                    id
                    roleHandle
                    name
                  }

                  docs{
                    id
                  name
                  value
                }

                  role {
                    id
                    roleHandle
                    name
                  }
                  cat{
                    id
                    prefix
                    description
                  }

                }
                totalCount
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        commit('SET_CURRENT_USER_PROCESSES', response.data.currentUserProcesses)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async refetchCurrentUserProcessesIfNotLoaded({ dispatch }) {
      dispatch('getCurrentUserProcesses', {
        offset: 0,
        limit: 1000,
      })
    },
    async addProcess({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation createProcess($input: CreateProcessInput!) {
              createProcess(createProcessInput: $input) {
                id
                processHandle
                name
                description
                frequencyType
                frequency {
                  ...FrequencyFragment
                }
                labels {
                  id
                  label
                }
                assignedRoles {
                  id
                  name
                  roleHandle
                }

                docs{
                  id
                  name
                  value
                }

                role{
                  id
                  name
                  roleHandle
                }
                cat{
                  id
                  prefix
                  description
                }
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            input: payload,
          },
        })
        commit('ADD_PROCESS', response.data.createProcess)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateProcess({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateProcess($input: UpdateProcessInput!) {
              updateProcess(updateProcessInput: $input) {
                id
                processHandle
                name
                description
                frequencyType
                frequency {
                  ...FrequencyFragment
                }
                labels {
                  id
                  label
                }
                assignedRoles {
                  id
                  name
                  roleHandle
                }

                docs{
                  id
                  name
                  value
                }

                role{
                  id
                  name
                  roleHandle
                  description
                }
                cat{
                  id
                  prefix
                  description
                }

              }
            }
            ${frequencyFragment}
          `,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_PROCESS', response.data.updateProcess)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeProcess({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeProcess($id: Int!) {
            removeProcess(id: $id)
          }`,
          variables: {
            id,
          },
        })
        if (response.data.removeProcess) {
          commit('REMOVE_PROCESS', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeProcesses({ commit }, ids) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeProcesses($ids: [Int!]!) {
            removeProcesses(ids: $ids)
          }`,
          variables: {
            ids,
          },
        })
        if (response.data.removeProcesses) {
          commit('REMOVE_PROCESSES', ids)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async importProcesses({ commit }, csvText) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation importProcesses($csvText
          : String!) {
            importProcesses(csvText: $csvText) {
              created
              updated
              errors {
                line
                message
                record
              }
              messages {
                line
                message
                record
              }
            }
          }`,
          variables: {
            csvText,
          },
        })
        if (response.data.importProcesses) {
          commit('SET_IMPORT_PROCESSES_RESULT', response.data.importProcesses)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async cancelProcessesImport({ commit }) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        await apolloClient.mutate({
          mutation: gql`mutation cancelProcessesImport {
            cancelProcessesImport
          }`,
        })
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getPossibleValuesForFieldFromProcessEntity({ commit }, field) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query getPossibleValuesForFieldFromProcessEntity($field: String!) {
            getPossibleValuesForFieldFromProcessEntity(field: $field) 
          }`,
          variables: {
            field,
          },
        })
        if (response.data.getPossibleValuesForFieldFromProcessEntity) {
          commit('SET_FIELD_VALUES', response.data.getPossibleValuesForFieldFromProcessEntity)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getCurrentUserProcessesStatsItem({ commit }, {
      filter, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query currentUserProcessesStatsItems($filter: String!, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
              currentUserProcessesStatsItems(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
                nodes {
                  id
                  processHandle
                  name
                  description
                  frequencyType
                  frequency {
                    ...FrequencyFragment
                  }
                  labels {
                    id
                    label
                  }
                  assignedRoles {
                    id
                    roleHandle
                    name
                  }

                  docs{
                    id
                  name
                  value
                }

                  role {
                    id
                    roleHandle
                    name
                  }
                  cat{
                    id
                    prefix
                    description
                  }

                }
                totalCount
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            filter,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        commit('SET_CURRENT_USER_PROCESSES', response.data.currentUserProcessesStatsItems)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getProcessesStatsItems({ commit }, {
      filter, offset, limit, sortBy, isDesc,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query processesStatsItems($filter: String!, $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
              processesStatsItems(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
                nodes {
                  id
                  processHandle
                  name
                  description
                  frequencyType
                  frequency {
                    ...FrequencyFragment
                  }
                  labels {
                    id
                    label
                  }
                  assignedRoles {
                    id
                    roleHandle
                    name
                  }

                  docs{
                    id
                  name
                  value
                }

                  role {
                    id
                    roleHandle
                    name
                  }
                  cat{
                    id
                    prefix
                    description
                  }

                }
                totalCount
              }
            }
            ${frequencyFragment}
          `,
          variables: {
            filter,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })

        commit('SET_PROCESSES', response.data.processesStatsItems)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    resetProcesses({ commit }) {
      commit('RESET_PROCESSES')
    },
  },
}
