<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <router-link :to="{path : `${route === 'dashboard-ecommerce' ? '/roles' : '/myRoles'}`}">
      <b-card-header
        header-bg-variant="info bg-darken-2"
        class="header"
      >
        <b-card-title class="title">
          <b-link class="text-props">
            <feather-icon
              size="22"
              icon="AwardIcon"
              class="text-white"
            />
          </b-link>
          Roles
        </b-card-title>
      </b-card-header>
    </router-link>

    <b-card-body>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <router-link :to="{path : `${route === 'dashboard-ecommerce' ? '/roles' : '/myRoles'}`}">
            <b-media-body>
              <h6 class="transaction-header ">
                <span>
                  <span class="text-props">{{ data.all }}</span>
                </span>
                Roles
              </h6>
            </b-media-body>
          </router-link>
        </b-media>
      </div>

      <div
        v-if="route === 'dashboard-ecommerce'"
        class="transaction-item"
      >
        <b-media no-body>
          <router-link
            :to="{name : `${route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list'}`, query: { q:'no:user' }}"
            link-class="text-props  text-primary"
          >
            <b-media-aside>

              <feather-icon
                v-if="data.noUsers > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title ">
              <router-link
                :to="{name : `${route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list'}`, query: { q:'no:user' }}"
                link-class="text-props  text-primary"
              >
                <span>{{ data.noUsers }}</span>
              </router-link>
              with no Person assigned
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{name : `${route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list'}`, query: { q:'no:process' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.noProcesses > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{name : `${route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list'}`, query: { q:'no:process' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.noProcesses }}</span>
              </router-link>
              Roles with no Process assigned
            </h6>
          </b-media-body>
        </b-media>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BLink,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { wrench } = $themeConfig.icons
    return {
      wrench,
    }
  },
  data() {
    return {
      route: this.$route.name,
    }
  },
}
</script>

<style scoped>
.media-style{
  background: none;
}
.text-props{
    color: #193a9a;
}
h6,span{
    font-weight: bold;
}
.header{
  margin-bottom: 10px;
}
.title{
  color: white;
}
.logo{
  height: 22px;
  width: 22px;
}
.transaction-title{
  color: black;
  font-weight: normal;
}

.transaction-header{
  color: black;
}
</style>
