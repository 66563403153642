<template>
  <div>
    <b-card class="container-card">
      <b-card-header class="pb-50">
        <h5>
          Matrix Attributes
        </h5>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent>
          <b-row>

            <!-- first name -->
            <b-col cols="6">
              <b-form-group
                label="Row"
                label-for="row"
              >
                <v-select
                  :value="rowSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="labelsPrefix"
                  :clearable="false"
                  :reduce="label => label.label"
                  @input="setSelectedRow"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Col"
                label-for="col"
              >
                <v-select
                  :value="colSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="labelsPrefix"
                  :reduce="label => label.label"
                  :clearable="false"
                  @input="setSelectedColumn"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="saveBtn && owner || owner"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="callUpdateMatrix"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="container-card"
    >
      <b-card-body class="table-card-body">
        <b-table
          striped
          hover
          class="matrix-table"
          :items="items"
        >
          <template #cell()="data">
            <div
              v-if="Number.parseFloat(data.value)"
              class="hyperlink-underlined cursor-pointer value-link"
              @click="gotoProcess(data)"
            >
              {{ data.value }}
            </div>
            <div
              v-else-if="data.value == 0"
              class="value-box"
              style="color:red;font-weight: bolder;"
            >
              {{ data.value }}
            </div>
            <div
              v-else
            >
              {{ data.value }}
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'OrgMatrix',
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: { owner: Boolean },
  data() {
    return {
      rowSelected: this.$route.query.row,
      colSelected: this.$route.query.column,
      id: this.$route.query.matrixId,
      rowOptions: [],
      colOptions: [],
      saveBtn: false,
    }
  },
  computed: {
    ...mapState({
      savedMatrix: state => state.matrix.savedMatrix,
      items: state => state.matrix.matrix,
      labelsPrefix: state => state.matrix.labelsPrefixes,
      user: state => state.user.currentUser,
    }),
  },
  watch: {
    savedMatrix() {
      const title = `Matrix ${this.savedMatrix} Updated`
      this.showToast(title)
    },
    rowSelected() {
      if (this.owner) {
        this.saveBtn = true
      }
      this.callGetMatrix()
    },
    colSelected() {
      if (this.owner) {
        this.saveBtn = true
      }
      this.callGetMatrix()
    },
  },
  mounted() {
    this.getMatrix({
      row: this.$route.query.row,
      col: this.$route.query.column,
    })
    this.getLabelsPrefix()
  },
  methods: {
    ...mapActions('matrix', ['getMatrix', 'getLabelsPrefix', 'updateMatrix']),
    // selected(item) {
    // console.log({ item })
    // this.$router.push({ path: '/details/:recordId' })
    // },
    gotoProcess(item) {
      let row = item.item[' ']
      let col = item.field.key
      if (col.includes('all')) {
        const position = col.search(':')
        col = col.slice(0, position)
        col = `${col}:all`
      }
      if (row.includes('all')) {
        const position = row.search(':')
        row = row.slice(0, position)
        row = `${row}:all`
      }
      this.$router.push({ path: '/processes', query: { q: `label:${col},label:${row}`, qd: `label:${col},label:${row}` } })
    },
    setSelectedColumn(value) {
      this.colSelected = value
    },
    setSelectedRow(value) {
      this.rowSelected = value
    },
    callGetMatrix() {
      this.getMatrix({
        row: this.rowSelected,
        col: this.colSelected,
      })
    },
    async makeMatrix(data) {
      const matrix = []
      for (let index = 0; index < data.length; index += 1) {
        let Row = {}
        const row = data[index]
        row.map(async item => {
          Row = { ...Row, [item.name]: item.value }
        })
        matrix.push(Row)
      }
      this.items = matrix
    },
    callUpdateMatrix() {
      this.updateMatrix({
        row: this.rowSelected,
        col: this.colSelected,
        id: parseFloat(this.id),
      })
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
.input {
  width: 40%;
  min-width: 40%;
}
.form {
  justify-content: space-around;
}
.matrix-table{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  td{
    padding: 10px!important;
  }
  th{
    padding: 10px!important;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle!important;
  }
  text-align: center;
  color: black!important;
}
.table-card-body{
  padding: 10px;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  overflow: auto;
}
.value-link{
  font-weight: bolder;
}
</style>
