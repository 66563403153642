import gql from 'graphql-tag'
import Vue from 'vue'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    organizationStatistics: null,
    isLoading: false,
    personalStatistics: null,
    pIsLoading: false,
  },
  getters: {
    // isLoggedIn: state => state.currentRole,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_ORGANIZATION_STATISTICS(state, val) {
      Vue.set(state, 'organizationStatistics', val)
    },
    USER_DASHBOARD(state, val) {
      state.personalStatistics = val
    },
    SET_P_LOADING(state, val) {
      state.pIsLoading = val
    },
  },
  actions: {
    async getOrganizationStatistics({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query organizationStatistics {
            organizationStatistics {
              processes {
                all
                scheduled
                scheduledNoDates
                needed
                noRoles
                noDescription
                noLabel
                noDocs
              }
              lateTasks {
                allLate
                all
                inProgress
                notStarted
              }
              roles {
                all
                noUsers
                noProcesses
              }
              users {
                all
                admins
                managers
                users
                noAccess
                noRole
              }
            }
          }`,
        })
        commit('SET_ORGANIZATION_STATISTICS', response.data.organizationStatistics)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchOrganizationStatisticsIfNotLoaded({ dispatch, state }) {
      if (!state.organizationStatistics) {
        dispatch('getOrganizationStatistics')
      }
    },
    async getUserStatistics({ commit }) {
      commit('SET_P_LOADING', true)
      try {
        const rows = await apolloClient.query({
          query: gql`
            query personalStatistics{
              personalStatistics {
                  roles {
                    all
                    noProcesses
                  }
                  lateTasks {
                    notStarted
                    inProgress
                    all
                    allLate
                  }
                  processes {
                    all
                    scheduled
                    scheduledNoDates
                    needed
                    noDescription
                    noLabel
                    noDocs
                  }
                }
            }
          `,
        })
        commit('USER_DASHBOARD', rows.data.personalStatistics)
      } finally {
        commit('SET_P_LOADING', false)
      }
    },
    async fetchPersonalStatisticsIfNotLoaded({ dispatch, state }) {
      if (!state.personalStatistics) {
        dispatch('getUserStatistics')
      }
    },
  },
}
