/* eslint-disable no-unused-vars */
import moment from 'moment'

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const DaysOfWeek = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
}

const DayFrequency = {
  Every: '*',
  First: '1',
  Second: '2',
  Third: '3',
  Fourth: '4',
  Fifth: '5',
  Last: 'L',
}

/**
 * handles timezone and formats date to YYYY-MM-DD
 * @param d
 */
function formatDate(d) {
  const offset = d.getTimezoneOffset()
  const date = new Date(d.getTime() - offset * 60 * 1000)
  return date.toISOString().split('T')[0]
}

/**
 *
 * @param monthAcronymWithYear: string; ex: 'Oct 2021'
 * @param dayFrequency: string[] ; ex: ['*', 'L'] , ['1']
 * @param dayName: DaysOfWeek
 * @param from: Date ; beginning of the interval
 * @param to: Date; end of the interval
 */
function getDatesOfDayNameDuringMonth(
  monthAcronymWithYear,
  dayName,
  dayFrequency,
  from,
  to,
) {
  const d = new Date(`1 ${monthAcronymWithYear}`)
  const month = d.getMonth()
  const dayNameDates = []

  // Get the first dayName (ex: monday) in the month
  while (d.getDay() !== dayName) {
    d.setDate(d.getDate() + 1)
  }

  if (dayFrequency && dayFrequency.find(e => e === '1') && d >= from && d <= to) dayNameDates.push(formatDate(new Date(d.getTime())))

  let position = 2

  while (d.getMonth() === month && d.getTime() <= to.getTime()) {
    if (dayFrequency && dayFrequency.includes(DayFrequency.Every) && d >= from && d <= to) dayNameDates.push(formatDate(new Date(d.getTime())))

    d.setDate(d.getDate() + 7)

    if (
      dayFrequency
      // eslint-disable-next-line no-loop-func
      && dayFrequency.find(e => e === position.toString())
      && d >= from
      && d <= to
    ) dayNameDates.push(formatDate(new Date(d.getTime())))
    position += 1
  }

  d.setDate(d.getDate() - 7)

  if (dayFrequency && dayFrequency.includes(DayFrequency.Last) && d >= from && d <= to) {
    dayNameDates.push(formatDate(new Date(d.getTime())))
  }
  return Array.from(new Set(dayNameDates))
}

function diff(from, to) {
  const arr = []
  const datFrom = new Date(from)
  const datTo = new Date(to)
  const fromYear = datFrom.getFullYear()
  const toYear = datTo.getFullYear()
  const diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

  for (let i = datFrom.getMonth(); i <= diffYear; i += 1) {
    arr.push(`${monthNames[i % 12]} ${Math.floor(fromYear + i / 12)}`)
  }
  return arr
}

// ---------------- Multi Week Interval ------------------
function getNextSpeceficDay(startDate, dayIndex) {
  const date = new Date(startDate)
  // eslint-disable-next-line no-mixed-operators
  date.setDate(date.getDate() + (dayIndex - 1 - date.getDay() + 7) % 7 + 1)
  return new Date(date)
}

function generateTasksFromInterval(
  StartOn,
  RepeatOn,
  RepeatEvery,
  from,
  to,
) {
  const tasks = []
  const weeksInterval = RepeatEvery * 7
  const dayIndex = DaysOfWeek[RepeatOn]
  const firstDayFromStartON = getNextSpeceficDay(StartOn, dayIndex)
  const firstDayInit = getNextSpeceficDay(from, dayIndex)
  const newStartDate = firstDayFromStartON > firstDayInit ? firstDayInit : firstDayFromStartON
  if (newStartDate <= firstDayInit && newStartDate.getMonth() === firstDayInit.getMonth()) {
    if (newStartDate.getMonth() === firstDayInit.getMonth()) {
      if (tasks.length === 0) {
        tasks.push(formatDate(new Date(firstDayInit)))
      }
    } else if (tasks.length === 0) {
      tasks.push(formatDate(new Date(newStartDate)))
    }
    while (new Date(tasks[tasks.length - 1]) < to) {
      const lastDate = new Date(tasks[tasks.length - 1])
      lastDate.setDate(lastDate.getDate() + weeksInterval)
      tasks.push(formatDate(lastDate))
    }
  }
  return tasks
}

function generateTasksFromFrequencyForMonth(
  monthAcronymWithYear,
  frequency,
  from,
  to,
) {
  const monthAcronym = monthAcronymWithYear.substring(0, 3)
  let taskDatesDuringInterval = []
  if (frequency && frequency[monthAcronym]) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const dayOfWeek in frequency[monthAcronym]) {
      let taskDatesOfDayNameDuringInterval = []
      if (dayOfWeek in DaysOfWeek) {
        taskDatesOfDayNameDuringInterval = getDatesOfDayNameDuringMonth(
          monthAcronymWithYear,
          DaysOfWeek[dayOfWeek],
          frequency[monthAcronym][dayOfWeek],
          from,
          to,
        )
      } else if (dayOfWeek === 'days') {
        if (frequency[monthAcronym][dayOfWeek]) {
          frequency[monthAcronym][dayOfWeek].forEach(day => {
            const d = new Date(`${day} ${monthAcronymWithYear}`)
            if (d >= from && d <= to) {
              taskDatesOfDayNameDuringInterval.push(formatDate(d))
            }
          })
        }
      }
      taskDatesDuringInterval = taskDatesDuringInterval.concat(
        taskDatesOfDayNameDuringInterval,
      )
    }
  }
  return Array.from(new Set(taskDatesDuringInterval))
}

export const generateTasksFromProcesses = (
  processes,
  from,
  to,
) => {
  const generatedTasks = []
  const monthsBetweenFromDateAndToDate = diff(from, to)
  processes.forEach(process => {
    if (process.frequency && process.frequency.Interval && process.frequency.Interval.StartOn) {
      const processTaskDatesDuringMultiWeekInterval = generateTasksFromInterval(
        new Date(process.frequency.Interval.StartOn),
        process.frequency.Interval.RepeatOn,
        process.frequency.Interval.RepeatEvery,
        from,
        to,
      )
      processTaskDatesDuringMultiWeekInterval.forEach(dueDate => {
        const generatedTask = {
          process,
          processId: process.id,
          dueDate,
          status: 'NotStarted',
          name: `${process.name} task`,
        }
        generatedTasks.push(generatedTask)
      })
    }
  })
  for (let i = 0; i < monthsBetweenFromDateAndToDate.length; i += 1) {
    processes.forEach(process => {
      const processTaskDatesDuringInterval = generateTasksFromFrequencyForMonth(
        monthsBetweenFromDateAndToDate[i],
        process.frequency,
        new Date(`${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`),
        to,
      )
      processTaskDatesDuringInterval.forEach(dueDate => {
        const generatedTask = {
          process,
          processId: process.id,
          dueDate,
          status: 'NotStarted',
          name: `${process.name} task`,
        }
        generatedTasks.push(generatedTask)
      })
    })
  }
  function compare(a, b) {
    if (a.dueDate < b.dueDate) {
      return -1
    }
    if (a.dueDate > b.dueDate) {
      return 1
    }
    return 0
  }
  const arrangedTasks = generatedTasks.sort(compare)
  while (new Date(arrangedTasks.at(-1).dueDate) > to) {
    arrangedTasks.pop()
  }
  return arrangedTasks
}

const canTaskBeAffectedToUser = (task, processesNodes, user) => {
  const taskProcess = processesNodes.find(process => process.id === task.processId)
  return taskProcess.assignedRoles.map(role => role.id).some(r => user.roleIds.includes(r))
}

export const generatedTasksFromProcessesAndAffectToUsers = (
  users,
  processes,
  from,
  to,
) => {
  const generatedAffectedToUsersTasksFromProcesses = []
  const generatedTasksFromProcesses = generateTasksFromProcesses(
    processes,
    from,
    to,
  )
  users.forEach(user => {
    generatedTasksFromProcesses.forEach(task => {
      if (canTaskBeAffectedToUser(task, processes, user)) {
        const userId = user.id
        const affectedToUserTask = {
          ...task,
          userId,
          assignee: user,
          handle: `${task.processId}-${userId}-${task.dueDate}`,
          init_handle: `${task.processId}-${userId}-${task.dueDate}`,
        }
        generatedAffectedToUsersTasksFromProcesses.push(affectedToUserTask)
      }
    })
  })
  const tasks = []
  generatedAffectedToUsersTasksFromProcesses.forEach(task => {
    const data = tasks.filter(el => el.init_handle === task.init_handle)
    if (data.length === 0) {
      tasks.push(task)
    }
  })
  return tasks
}

/**
 * tasksNodes: tasks persisted in the database
 * usersNodes: user nodes we want to generate the tasks for
 * processNodes: process nodes we want to generate the tasks from
 * from: beginning of the date range
 * to: end of the date rage
 * javascriptFilteringFunctions: array of strings that are going to be evaluated as javascript functions and used for filtering
 */
export const generatedTasksMergedWithPersistedTasks = (tasksNodes, usersNodes, processNodes, from, to, javascriptFilteringFunctions = []) => {
  // console.log(usersNodes)
  // console.log('taskNodes',tasksNodes)
  // console.log(processNodes)
  // console.log('javascriptFilteringFunctions', javascriptFilteringFunctions)
  // 1- check if from is null. If it is null search for the earliest due date in tasksNodes
  if (!from) {
    const sortedDatesOfPersistedTasks = tasksNodes.map(task => task.dueDate).sort((a, b) => Date.parse(a) > Date.parse(b))
    // eslint-disable-next-line no-param-reassign
    from = new Date(sortedDatesOfPersistedTasks[0])
  }

  // 2- return the tasks from database that are within the date range
  const persistedTasksWithinDateRange = tasksNodes.filter(task => new Date(task.dueDate) >= from && new Date(task.dueDate) <= to)

  // 3 - return the generated tasks from all processes
  let generatedTasksResult = generatedTasksFromProcessesAndAffectToUsers(usersNodes, processNodes, from, to)

  // 4 - replace the generated tasks by the ones in the database
  persistedTasksWithinDateRange.forEach((persistedTaskWithinDateRange, index) => {
    const indexOfTaskToReplace = generatedTasksResult.findIndex(task => persistedTaskWithinDateRange.init_handle.includes(task.handle) && !persistedTasksWithinDateRange.isManuallyCreated)
    if (indexOfTaskToReplace !== -1) generatedTasksResult.splice(indexOfTaskToReplace, 1, persistedTasksWithinDateRange[index])
    else generatedTasksResult.push(persistedTasksWithinDateRange[index])
  })

  const momentFun = () => moment
  // 5 - apply javascript filtering functions
  if (javascriptFilteringFunctions.length !== 0) {
    const filteringString = javascriptFilteringFunctions.join(' && ')
    const newMoment = momentFun()
    // eslint-disable-next-line no-eval,no-unused-vars
    generatedTasksResult = generatedTasksResult.slice().filter(task => eval(filteringString))
  }

  // 6- sort the generated tasks result by due date and assignee id
  // eslint-disable-next-line no-nested-ternary
  generatedTasksResult.sort((a, b) => ((new Date(a.dueDate) > new Date(b.dueDate)) ? 1 : (new Date(b.dueDate) > new Date(a.dueDate)) ? -1 : 0 && a.userId - b.userId))

  return generatedTasksResult
}

/**
 * tasks: tasks persisted in the database
 *
 */
/* export const generatedTasksMergedWithPersistedTasks = (tasksNodes, usersNodes, processNodes, from, to, javascriptFilteringFunctions = [], showDeletedTasks = false) => {
  // 1- return the tasks from database that are within the date range
  const persistedTasksWithinDateRange = tasksNodes.filter(task => new Date(task.dueDate) >= from && new Date(task.dueDate) <= to)
  // 2 - return the generated tasks from all processes
  const generatedTasksTasksResult = generatedTasksFromProcessesAndAffectToUsers(usersNodes, processNodes, from, to)
  // 3 - return the persisted non deleted tasks
  const persistedNonDeletedTasks = persistedTasksWithinDateRange.filter(task => !task.deleted)
  // 4- return the persisted deleted tasks
  const persistedDeletedTasks = persistedTasksWithinDateRange.filter(task => task.deleted === true)

  // The logic is that we are going to always replace the generated tasks by the ones from the database

  const filteredTasksUsingSearchQuery = generatedTasksTasksResult

  let filteredGeneratedTasks = []
  if (!showDeletedTasks) {
    filteredGeneratedTasks = filteredTasksUsingSearchQuery.filter(task => !persistedDeletedTasks.find(taskToRemove => taskToRemove.handle === task.handle))
  } else {
    persistedDeletedTasks.forEach((persistedDeletedTask, index) => {
      const indexOfTaskToReplace = filteredTasksUsingSearchQuery.findIndex(task => task.handle === persistedDeletedTask.handle)
      if (indexOfTaskToReplace !== -1) filteredTasksUsingSearchQuery.splice(indexOfTaskToReplace, 1, persistedDeletedTasks[index])
    })
    filteredGeneratedTasks = filteredTasksUsingSearchQuery
  }

  const reFilteredGeneratedTasks = filteredGeneratedTasks.filter(task => !persistedNonDeletedTasks.find(taskToRemove => taskToRemove.handle === task.handle))

  let reFilteredGeneratedTasksConcatenatedWithPersistedNonDeletedTasks = reFilteredGeneratedTasks.concat(persistedNonDeletedTasks)

  if (javascriptFilteringFunctions.length !== 0) {
    const filteringString = javascriptFilteringFunctions.join(' || ')
    // eslint-disable-next-line no-eval,no-unused-vars
    reFilteredGeneratedTasksConcatenatedWithPersistedNonDeletedTasks = reFilteredGeneratedTasksConcatenatedWithPersistedNonDeletedTasks.slice().filter(task => eval(filteringString))
  }
  // eslint-disable-next-line no-nested-ternary
  return reFilteredGeneratedTasksConcatenatedWithPersistedNonDeletedTasks.sort((a, b) => ((a.dueDate > b.dueDate) ? 1 : (b.dueDate > a.dueDate) ? -1 : 0))
} */

/* export const generatedTasksMergedWithPersistedTasks = (tasksNodes, usersNodes, processNodes, from, to) => {
  const tasksWithinDateRange = tasksNodes.filter(task => new Date(task.dueDate) >= from && new Date(task.dueDate) <= to)
  const generatedTasksWithoutDeletedTasksResult = generatedTasksFromProcessesAndAffectToUsers(usersNodes, processNodes, from, to)
  const persistedNonDeletedTasks = tasksWithinDateRange.filter(task => !task.deleted)
  const persistedDeletedTasks = tasksWithinDateRange.filter(task => task.deleted === true)
  const filteredTasksUsingSearchQuery = generatedTasksWithoutDeletedTasksResult

  /* if (this.isCurrentQueryComplete && this.filteringFunctions.length !== 0) {
    // eslint-disable-next-line no-eval,no-unused-vars
    filteredTasksUsingSearchQuery = generatedTasksWithoutDeletedTasksCopy.slice().filter(task => eval(this.filterTasksTableFunction))
  } */
/* const filteredGeneratedTasks = filteredTasksUsingSearchQuery.filter(task => !persistedDeletedTasks.find(taskToRemove => taskToRemove.handle === task.handle))
  const reFilteredGeneratedTasks = filteredGeneratedTasks.filter(task => !persistedNonDeletedTasks.find(taskToRemove => taskToRemove.handle === task.handle))
  // eslint-disable-next-line no-nested-ternary
  return reFilteredGeneratedTasks.concat(persistedNonDeletedTasks).sort((a, b) => ((a.dueDate > b.dueDate) ? 1 : (b.dueDate > a.dueDate) ? -1 : 0))
} */
