<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <router-link :to="{path : `${route === 'dashboard-ecommerce' ? '/tasks' : '/myTasks'}`}">
      <b-card-header
        header-bg-variant="success"
        class="header"
      >
        <b-card-title class="title">
          <b-link>
            <feather-icon
              size="22"
              icon="CheckSquareIcon"
              class="text-white"
            /></b-link>
          Tasks
        </b-card-title>
      </b-card-header>
    </router-link>

    <b-card-body>

      <div class="transaction-item">
        <b-media no-body>
          <router-link :to="{path : `${route === 'dashboard-ecommerce' ? '/tasks' : '/myTasks'}`, query: { q: 'is:late', d: 'org' }}">
            <b-media-body>
              <h6 class="transaction-header">
                <router-link
                  :to="{ name: `${route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list'}`, query: { q: 'is:late', d: 'org' }}"
                  link-class="text-props text-primary"
                >
                  <span>{{ data.allLate }}</span>
                </router-link>
                <span v-if="data.allLate == 1 || 0">
                  Task is late
                </span>
                <span v-else>
                  Tasks are late
                </span>
              </h6>
            </b-media-body>
          </router-link>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{ name: `${route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list'}`, query: { q: 'is:late&inProgress', d: 'org' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                v-if="data.inProgress > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="LoaderIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title ">
              <router-link
                :to="{ name: `${route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list'}`, query: { q: 'is:late&inProgress', d: 'org' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.inProgress }}</span>
              </router-link>
              are late and in Progress
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{ name: `${route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list'}`, query: { q: 'is:late&notStarted', d: 'org' }}"
            link-class="text-props text-primary"
          >
            <b-media-aside>

              <feather-icon
                v-if="data.notStarted > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-danger"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />

            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{ name: `${route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list'}`, query: { q: 'is:late&notStarted', d: 'org' }}"
                link-class="text-props text-primary"
              >
                <span>{{ data.notStarted }}</span>
              </router-link>
              are late and Not Started
            </h6>
          </b-media-body>
        </b-media>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BLink,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { generatedTasksMergedWithPersistedTasks } from '@/utils/generate-tasks'
import { DAYS_BETWEEN_DEFAULT_INTERVAL } from '@/utils/constants'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BLink,
  },
  setup() {
    const { wrench } = $themeConfig.icons
    return {
      wrench,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      route: this.$route.name,
      from: '',
      to: new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() + DAYS_BETWEEN_DEFAULT_INTERVAL)),
      counts: {},
    }
  },
  computed: {
    ...mapState('task', [
      'tasks',
      'fieldValues',
      'currentUserTasks',
    ]),
    ...mapState('task', { taskIsLoading: 'isLoading' }),
    ...mapState('process', ['processes', 'allProcessesForTasks']),
    ...mapState('process', { processIsLoading: 'isLoading' }),
    ...mapState('user', ['users', 'allUsers', 'currentUser']),
    ...mapState('user', { userIsLoading: 'isLoading' }),
    ...mapState('filter', ['filtersAccessibleToCurrentUser']),
  },
  watch: {
    allUsers() {
      this.regenerateTasksToDisplay()
    },
    allProcessesForTasks() {
      this.regenerateTasksToDisplay()
    },
  },
  created() {
    if (this.route === 'dashboard-ecommerce') {
      this.getTasks({
        sortBy: 'id',
      }).then(async () => {
        const date = await this.oldestDate(this.tasks)
        this.from = date > Date.now ? this.from : date
      }).then(() => {
        this.fetchUsersIfNotLoaded().then(() => {
          this.fetchProcessesForTasksIfNotLoaded({})
        })
      })
        .then(() => {
          this.regenerateTasksToDisplay()
        })
        .catch(error => error)
    } else {
      this.getCurrentUserTasks({
        sortBy: 'id',
      }).then(async () => {
        const date = await this.oldestDate(this.currentUserTasks)
        this.from = date > Date.now ? this.from : date
      }).then(() => {
        this.fetchUsersIfNotLoaded().then(() => {
          this.fetchProcessesForTasksIfNotLoaded({})
        })
      })
        .then(() => {
          this.regenerateTasksToDisplay()
        })
        .catch(error => error)
    }
    // const lastInterval = this.to
    // lastInterval.setDate(lastInterval.getDate() + DAYS_BETWEEN_DEFAULT_INTERVAL)
    // this.to = lastInterval
  },
  methods: {
    ...mapActions('task', [
      'getTasks',
      'getPossibleValuesForFieldFromTaskEntity',
      'getCurrentUserTasks',
    ]),
    ...mapActions('process', ['getProcesses', 'fetchProcessesForTasksIfNotLoaded']),
    ...mapActions('user', ['fetchUsersIfNotLoaded']),
    ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
    async oldestDate(taskArr) {
      if (taskArr.nodes.length !== 0) {
        let date = taskArr.nodes[0].dueDate
        taskArr.nodes.forEach(el => {
          if (el.dueDate < date) {
            date = el.dueDate
          }
        })
        return new Date(date)
      }
      return new Date(Date.now())
    },
    regenerateTasksToDisplay() {
      const state = this.allProcessesForTasks.nodes.length !== 0
      const state2 = this.allUsers.nodes.length !== 0
      if (state && state2) {
        const filtersFuncs = [{ name: 'lateNotStarted', func: 'newMoment(task.dueDate).diff(Date.now(), \'days\') < 0 && task.status == \'NotStarted\' && !task.deleted' }, { name: 'lateInProgress', func: 'newMoment(task.dueDate).diff(Date.now(), \'days\') < 0 && task.status == \'InProgress\' && !task.deleted' }, { name: 'late', func: 'newMoment(task.dueDate).diff(Date.now(), \'days\') < 0 && task.status != \'Complete\' && !task.deleted' }]
        filtersFuncs.forEach(filterFunc => {
          let tasksToDisplay = []
          if (this.route === 'dashboard-ecommerce') {
            tasksToDisplay = generatedTasksMergedWithPersistedTasks(this.tasks.nodes, this.allUsers.nodes, this.allProcessesForTasks.nodes, this.from, this.to, [filterFunc.func])
          } else {
            tasksToDisplay = generatedTasksMergedWithPersistedTasks(this.currentUserTasks.nodes, [this.currentUser], this.allProcessesForTasks.nodes, this.from, this.to, [filterFunc.func])
          }
          this.counts = { ...this.counts, [filterFunc.name]: tasksToDisplay.length }
        })
      } else {
        this.fetchUsersIfNotLoaded().then(() => {
          this.fetchProcessesForTasksIfNotLoaded()
        })
      }
    },
  },
}
</script>

<style scoped>
.media-style{
  background: none;
}

h6,span{
    font-weight: bold;
}
.header{
  margin-bottom: 10px;
}
.title{
  color: white;
}
.logo{
  height: 22px;
  width: 22px;
}
.transaction-title{
  color: black;
  font-weight: normal;
}

.transaction-header{
  color: black;
}
</style>
