import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'
import { Update, Remove, MultiRemove } from '@/@core/mixins/store/updateNodes'

export default {
  namespaced: true,
  state: {
    roles: {
      nodes: [],
      totalCount: 0,
    },
    importRolesResult: {},
    isLoading: false,
    currentUserRoles: {
      nodes: [],
      totalCount: 0,
    },
    allRoles: {
      nodes: [],
      totalCount: 0,
    },
    fieldValues: [],
    role: {},
  },
  getters: {
    // isLoggedIn: state => state.currentRole,
    fieldValues: state => state.fieldValues,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_ROLES(state, val) {
      state.roles = val
    },
    SET_ROLE(state, val) {
      state.role = val.role
    },
    SET_CURRENT_USER_ROLES(state, val) {
      state.currentUserRoles = val
    },
    SET_ALL_ROLES(state, val) {
      state.allRoles = val
    },
    ADD_ROLE(state, val) {
      state.roles.nodes.unshift(val)
      state.roles.totalCount += 1
      state.allRoles.nodes.unshift(val)
      state.allRoles.totalCount += 1
    },
    UPDATE_ROLE(state, val) {
      const roles = state.roles.nodes.findIndex(item => item.id === val.id)
      const currentUserRoles = state.currentUserRoles.nodes.findIndex(item => item.id === val.id)
      const allRoles = state.allRoles.nodes.findIndex(item => item.id === val.id)
      const rolesIndexes = [{ roles }, { currentUserRoles }, { allRoles }]
      Update(rolesIndexes, state, val, Vue)
      if (val.id === state.role.id) {
        state.role = { ...state.role, ...val }
      }
    },
    REMOVE_ROLE(state, id) {
      const roles = state.roles.nodes.findIndex(item => item.id === id)
      const currentUserRoles = state.roles.nodes.findIndex(item => item.id === id)
      const allRoles = state.roles.nodes.findIndex(item => item.id === id)
      const rolesIndexes = [{ roles }, { currentUserRoles }, { allRoles }]
      Remove(rolesIndexes, state)
    },
    REMOVE_ROLES(state, ids) {
      const rolesIndexes = [{ roles: 0 }, { currentUserRoles: 0 }, { allRoles: 0 }]
      MultiRemove(rolesIndexes, ids, state)
    },
    SET_IMPORT_ROLES_RESULT(state, result) {
      state.importRolesResult = result
    },
    SET_FIELD_VALUES(state, result) {
      state.fieldValues = result
    },
    RESET_ROLES(state) {
      state.roles = {
        nodes: [],
        totalCount: 0,
      }
      state.allRoles = {
        nodes: [],
        totalCount: 0,
      }
    },
  },
  actions: {
    async getRole({ commit }, { id, relations }) {
      const response = await apolloClient.query({
        query: gql`query role($id:Int!){
            role(id: $id){
              id
              description
              assignedProcesses {
                  id
                  name
                  processHandle
                }
                process{
                  id
                  name
                  processHandle
                }
            }
          }`,
        variables: {
          id,
          relations,
        },
      })
      commit('SET_ROLE', response.data)
    },
    async getRoles({ commit }, {
      q, roleType, offset, limit, sortBy, isDesc, filledBy,
    }) {
      if (limit < 1000) {
        commit('SET_LOADING', true)
      }
      try {
        const response = await apolloClient.query({
          query: gql`query roles($q: [String!], $filledBy: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            roles(q: $q, filledBy: $filledBy, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                roleHandle
                name
                reportsToId
                filledBy {
                  id
                  name
                }
                reportsTo {
                  id
                  roleHandle
                }
              }
              totalCount
            }
          }`,
          variables: {
            q,
            roleType,
            offset,
            limit,
            sortBy,
            isDesc,
            filledBy,
          },
        })

        if (limit === 1000) {
          commit('SET_ALL_ROLES', response.data.roles)
        } else {
          commit('SET_ROLES', response.data.roles)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getCurrentUserRoles({ commit }, {
      q, roleType, offset, limit, sortBy, isDesc, filledBy,
    }) {
      if (limit < 1000) {
        commit('SET_LOADING', true)
      }

      try {
        const response = await apolloClient.query({
          query: gql`query currentUserRoles($q: [String!], $filledBy: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            currentUserRoles(q: $q, filledBy: $filledBy, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                roleHandle
                name
                description
                reportsToId
                filledBy {
                  id
                  name
                }
                reportsTo {
                  id
                  roleHandle
                }
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                process{
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
              }
              totalCount
            }
          }`,
          variables: {
            q,
            roleType,
            offset,
            limit,
            sortBy,
            isDesc,
            filledBy,
          },
        })
        commit('SET_CURRENT_USER_ROLES', response.data.currentUserRoles)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchRolesIfNotLoaded({ dispatch, state }) {
      if (!state.allRoles.totalCount) {
        dispatch('getRoles', {
          offset: 0,
          limit: 1000,
          sortBy: 'id',
          isDesc: false,
        })
      }
    },
    async refetchCurrentUserRolesIfNotLoaded({ dispatch, state }) {
      if (!state.currentUserRoles.totalCount) {
        dispatch('getCurrentUserRoles', {
          offset: 0,
          limit: 1000,
        })
      }
    },
    async addRole({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation createRole($input: CreateRoleInput!) {
            createRole(createRoleInput: $input) {
              id
              roleHandle
              name
              description
              reportsToId
              filledBy {
                id
                name
              }
              reportsTo {
                id
                roleHandle
              }
              assignedProcesses {
                id
                name
              }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('ADD_ROLE', response.data.createRole)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateRole({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation updateRole($input: UpdateRoleInput!) {
            updateRole(updateRoleInput: $input) {
                id
                roleHandle
                name
                description
                reportsToId
                filledBy {
                  id
                  name
                }
                reportsTo {
                  id
                  roleHandle
                }
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  role{
                    id
                    name
                  }
                }
                process{
                  id
                  name
                  processHandle
                }
            }
          }`,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_ROLE', response.data.updateRole)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeRole({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeRole($id: Int!) {
            removeRole(id: $id)
          }`,
          variables: {
            id,
          },
        })
        if (response.data.removeRole) {
          commit('REMOVE_ROLE', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeRoles({ commit }, ids) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation removeRoles($ids: [Int!]!) {
            removeRoles(ids: $ids)
          }`,
          variables: {
            ids,
          },
        })
        if (response.data.removeRoles) {
          commit('REMOVE_ROLES', ids)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async importRoles({ commit }, csvText) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`mutation importRoles($csvText
          : String!) {
            importRoles(csvText: $csvText) {
              created
              updated
              errors {
                line
                message
                record
              }
              messages {
                line
                message
                record
              }
            }
          }`,
          variables: {
            csvText,
          },
        })
        if (response.data.importRoles) {
          commit('SET_IMPORT_ROLES_RESULT', response.data.importRoles)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getPossibleValuesForFieldFromRoleEntity({ commit }, field) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query getPossibleValuesForFieldFromRoleEntity($field: String!) {
            getPossibleValuesForFieldFromRoleEntity(field: $field) 
          }`,
          variables: {
            field,
          },
        })
        if (response.data.getPossibleValuesForFieldFromRoleEntity) {
          commit('SET_FIELD_VALUES', response.data.getPossibleValuesForFieldFromRoleEntity)
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async cancelRolesImport({ commit }) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        await apolloClient.mutate({
          mutation: gql`mutation cancelRolesImport {
            cancelRolesImport
          }`,
        })
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getCurrentUserRolesStatsItems({ commit }, {
      filter, roleType, offset, limit, sortBy, isDesc, filledBy,
    }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`query currentUserRolesStatsItems($filter: String!, $filledBy: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            currentUserRolesStatsItems(filter: $filter, filledBy: $filledBy, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                roleHandle
                name
                description
                reportsToId
                filledBy {
                  id
                  name
                }
                reportsTo {
                  id
                  roleHandle
                }
                assignedProcesses {
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
                process{
                  id
                  name
                  processHandle
                  description
                  assignedRoles{
                    id
                    name
                  }
                  labels{
                    id
                    label
                  }
                  docs{
                    id
                    name
                    value
                  }
                  role{
                    id
                    name
                  }
                }
              }
              totalCount
            }
          }`,
          variables: {
            filter,
            roleType,
            offset,
            limit,
            sortBy,
            isDesc,
            filledBy,
          },
        })
        commit('SET_CURRENT_USER_ROLES', response.data.currentUserRolesStatsItems)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getRolesStatsItems({ commit }, {
      filter, roleType, offset, limit, sortBy, isDesc, filledBy,
    }) {
      commit('SET_LOADING', true)

      try {
        const response = await apolloClient.query({
          query: gql`query rolesStatsItems($filter: String!, $filledBy: [String!], $offset: Int, $limit: Int, $sortBy: String, $isDesc: Boolean) {
            rolesStatsItems(filter: $filter, filledBy: $filledBy, offset: $offset, limit: $limit, sortBy: $sortBy, isDesc: $isDesc) {
              nodes {
                id
                roleHandle
                name
                reportsToId
                filledBy {
                  id
                  name
                }
                reportsTo {
                  id
                  roleHandle
                }
              }
              totalCount
            }
          }`,
          variables: {
            filter,
            roleType,
            offset,
            limit,
            sortBy,
            isDesc,
            filledBy,
          },
        })

        commit('SET_ROLES', response.data.rolesStatsItems)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    resetRoles({ commit }) {
      commit('RESET_ROLES')
    },
  },
}
