<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="$refs.refFormObserver && $refs.refFormObserver.flags.dirty"
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template
      #default="{ hide }"
    >
      <!-- Header -->
      <div class="sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title() }}
        </h5>

        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(SaveMatrix)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <h6>Name</h6>
            <b-form-group>
              <b-form-input
                id="matrix-1"
                v-model="form.name"
                maxlength="255"
                :state="getValidationState(validationContext)"
                placeholder="Please provide matrix name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Column Select -->
          <validation-provider
            #default="validationContext"
            name="Column"
            rules="required"
          >

            <h6>Column</h6>
            <b-form-group :state="getValidationState(validationContext)">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="labelsPrefix"
                :value="form.col"
                :reduce="label => label.label"
                placeholder="please choose matrix column"
                @input="setSelectedColumn"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Row"
            rules="required"
          >

            <h6>Row</h6>
            <b-form-group :state="getValidationState(validationContext)">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="labelsPrefix"
                :value="form.row"
                :reduce="label => label.label"
                placeholder="please choose matrix row"
                @input="setSelectedRow"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
          <b-form-checkbox
            v-model="form.privacy"
            :value="true"
            class="custom-control-success"
          >
            Private
          </b-form-checkbox>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mr-1"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="handleSubmit()"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    saving: {
      type: Function,
      default: () => this.saving(),
    },
    value: {
      type: Object,
      default: () => this.getDefaultForm(),
    },
  },
  data() {
    return {
      form: this.getDefaultForm(),
      name: null,
      selectedColumn: null,
      selectedRow: null,
      columnOptions: [],
      rowOptions: [],
    }
  },
  computed: {
    ...mapState({
      labelsPrefix: state => state.matrix.labelsPrefixes,
    }),
  },
  watch: {
    labelsPrefix() {
      this.columnOptions = this.labelsPrefix
      this.rowOptions = this.labelsPrefix
    },
    value(value) {
      if (value) {
        this.form = {
          ...value,
        }
      }
    },
  },
  mounted() {
    this.getLabelsPrefix()
  },
  methods: {
    ...mapActions('matrix', ['getLabelsPrefix', 'createSavedMatrix', 'updateMatrix']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    title() {
      if (this.isEdit) {
        return `${this.value.name} Edit`
      }
      return 'Add New Matrix'
    },
    setSelectedColumn(value) {
      this.form.col = value
    },
    setSelectedRow(value) {
      this.form.row = value
    },
    SaveMatrix() {
      const {
        col, row, privacy, name, id,
      } = this.form
      if (this.isEdit) {
        this.updateMatrix({
          col, row, privacy, name, id,
        }).then(this.$emit('update:is-sidebar-active', false))
          .then(this.$emit('saving'))
      } else {
        this.createSavedMatrix({
          col, row, name, privacy,
        }).then(this.$emit('update:is-sidebar-active', false))
          .then(this.$emit('saving'))
      }
    },
    getDefaultForm() {
      return {
        row: null,
        col: null,
        privacy: false,
      }
    },
    resetForm() {
      this.$emit('update:value', this.getDefaultForm())
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      this.form = this.getDefaultForm()
    },
  },
}

</script>
