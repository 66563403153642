import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import i18n from '@/libs/i18n'
import firebase from '@/libs/firebase'
import apolloProvider from '@/libs/vue-apollo'
import { initialAbility } from '@/libs/acl/config'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/vue-debounce'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// import moment
Vue.prototype.moment = moment

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

let app
firebase.auth().onAuthStateChanged(async user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      apolloProvider,
      render: h => h(App),
    }).$mount('#app')
  }

  if (user) {
    await store.dispatch('user/fetchCurrentUserIfNotLoaded')
      .then(async () => {
        await store.commit('auth/SET_CURRENT_USER', user)
        /* if (store.state.user.currentUser.userType === 'User') await store.dispatch('auth/setRole', 'user')
        else if (store.state.user.currentUser.userType === 'Admin') await store.dispatch('auth/setRole', 'admin')
        else if (store.state.user.currentUser.userType === 'Manager') await store.dispatch('auth/setRole', 'manager') */
      }).catch(error => {
        // logout from firebase
        firebase.auth().signOut()
        Vue.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
  } else {
    /* eslint-disable no-underscore-dangle */
    const redirectLogin = app.$router.history._startLocation

    app.$ability.update(initialAbility)
    if (app.$router.currentRoute.name !== 'auth-login') {
      app.$router.push(redirectLogin)
    }
  }
})
